.brand-btn {
  padding: $spacer-3 $spacer-4;
  font-size: $font-md;
  font-weight: $font-weight-normal;
  text-align: center;
  white-space: nowrap;
  border-radius: 6px;
  line-height: 100%;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-family: $default-font-family;
  transition: 0.8s;

  &:active {
    transform: scale(0.9);
  }

  em {
    margin: $spacer-0 $spacer-2;
    color: $brand-color-white;
    font-size: $font-md;
  }

  &--inline {
    background-color: $brand-color-transparent;
    color: $brand-color-secondary;
    border: 1px solid transparent;
    padding: 0 !important;
    display: flex;
    margin-left: 2px;

    em {
      color: $brand-color-secondary;
    }

    &-icon {
      em {
        margin: $spacer-0;
      }
    }
  }

  &--icon-left {
    flex-direction: row-reverse;

    em {
      margin-left: $spacer-0;
      margin-right: $spacer-2;
    }
  }

  &--secondary {
    background-color: $brand-color-white;
    color: $brand-color-secondary;
    border: 1px solid $brand-color-secondary;

    em {
      color: $brand-color-secondary;
    }

    &:hover {
      background-color: $brand-color-secondary;
      color: $brand-color-white;

      em {
        color: $brand-color-white;
      }
    }
  }

  &--primary {
    background-color: $brand-color-secondary;
    color: $brand-color-white;
    border: 1px solid $brand-color-secondary;

    &:hover {
      background-color: $brand-color-secondary;
      border: 1px solid $brand-color-secondary;
      color: $brand-color-white;

      em {
        color: $brand-color-white;
      }
    }
  }

  &--sm {
    padding: 2px 4px !important;
  }

  &--md {
    padding: 5px 10px !important;
    font-size: 1rem;
  }

  &--md-inline {
    font-size: 1.2rem;
  }

  &--lg {
    padding: 10px 20px !important;
  }

  &--icon-hide {
    display: none;
  }
}
