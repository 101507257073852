// Mixins

// Breakpoints

// Mobile only css. @media(max-width: 767px)
@mixin mobile {
  @media (max-width: ($bp-mobile - 0.02)) {
    @content;
  }
}

// Tablet only css. @media(min-width: 600px) and (max-width: 1024px)
@mixin tablet {
  @media (max-width: ($bp-tablet)) {
    @content;
  }
}

// Desktop only css. @media(min-width: 1024px)
@mixin desktop {
  @media (max-width: $bp-tablet) {
    @content;
  }
}

// Mobile and Tablet only css. @media(max-width: 1024px)
@mixin mobile-tablet {
  @media (max-width: ($bp-tablet - 0.02)) {
    @content;
  }
}

// Tablet and Desktop only css. @media(min-width: 600px)
@mixin tablet-desktop {
  @media (min-width: $bp-mobile) {
    @content;
  }
}

// Tablet and Desktop only css. @media(min-width: 1400px)
@mixin desktop-xl {
  @media (min-width: 1400px) {
    @content;
  }
}

// for internet explorer
@mixin ie-browser {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}
