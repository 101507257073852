.brand-card {
  background-color: $brand-color-white;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border: inherit !important;
  background-clip: border-box;
  border-radius: 7px;

  &__body {
    flex: 1 1 auto;
    padding: $spacer-5;
    margin: 0;
    position: relative;
  }
}
