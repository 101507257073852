/* You can add global styles to this file, and also import other style files */
@import "./assets/brand-scss/style";
@import "../node_modules/bootstrap/scss/bootstrap";
@import 'ngx-toastr/toastr';



html,
body {
  height: 100%;
}

body {
  margin: 0;
  background-color: #dae1f0;
  // font-family: $default-font-family !important;
  // background: #050813;

}

h1{
  color:  #ffffff;
}

html, body { height: 100%; }
body { margin: 0; font-family: "Jost", sans-serif; }
html, body { height: 100%; }
body { margin: 0; font-family: "Jost", sans-serif; }
.color-blue {
  color: #214598 !important;
}

