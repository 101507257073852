// Variables and Mixins
@import "./abstracts/";

// Reset, Base & Fonts
@import "./base/";

// Layout
@import "./layout/";

// Components
@import "./components/";

.qrCode {
  width: 124px;

  position: fixed;
  bottom: 105%;
  right: 14px;
  font-size: 24px;
  z-index: 99;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s ease;
  bottom: 100px;
  border: none;
  background-color: transparent;
  img {
    position: absolute;
    bottom: 10px;
    background-color: #fff;
    height: 78px;
    right: 0;
  }
  .p1 {
    width: 78px;
    font-size: 14px;
    background-color: #fff;
    color: #214598;
    top: -103px;
    right: 0px;
    position: absolute;
    font-weight: 600;
  }
  .p2 {
    width: 78px;

    font-size: 14px;
    background-color: #fff;
    color: #214598;
    bottom: -21px;
    right: 0;
    position: absolute;
    font-weight: 600;
  }
}
